<template>
  <!-- 员工风采 -->
  <div class="staffStyle">
    <div class="main">
      <div class="title">员工风采</div>
      <div class="content">
        <!-- 第一行 -->
        <ul>
          <li class="mini left">
            <img :src="staffStyleList[0]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[1]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[2]" />
          </li>
          <li class="small"></li>
          <li class="small">
            <img :src="staffStyleList[3]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[4]" />
          </li>
          <li class="mini right">
            <img :src="staffStyleList[5]" />
          </li>
        </ul>
        <!-- 第二行 -->
        <ul>
          <li class="small">
            <img :src="staffStyleList[6]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[7]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[8]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[9]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[10]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[11]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[12]" />
          </li>
        </ul>
        <!-- 第三行 -->
        <ul>
          <li class="mini left">
            <img :src="staffStyleList[13]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[14]" />
          </li>
          <li class="big">
            <img :src="staffStyleList[15]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[16]" />
          </li>
          <li class="mini right">
            <img :src="staffStyleList[17]" />
          </li>
        </ul>
        <!-- 第四行 -->
        <ul>
          <li class="small"></li>
          <li class="mini left">
            <img :src="staffStyleList[18]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[19]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[20]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[21]" />
          </li>
          <li class="mini right">
            <img :src="staffStyleList[22]" />
          </li>
          <li class="small"></li>
        </ul>
        <!-- 第五行 -->
        <ul>
          <li class="small"></li>
          <li class="small"></li>
          <li class="mini left">
            <img :src="staffStyleList[23]" />
          </li>
          <li class="small">
            <img :src="staffStyleList[24]" />
          </li>
          <li class="mini right">
            <img :src="staffStyleList[25]" />
          </li>
          <li class="small"></li>
          <li class="small"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";

export default {
  name: "staffStyle",
  data() {
    return {
      staffStyleList: [],
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "员工风采",
    }).then((res) => {
      this.staffStyleList = res.data.rows.map((item) => {
        return item.photoPicture;
      });
    });
  },
};
</script>
<style scoped lang='less'>
@media screen and(max-width:1150px) {
  .content {
    li {
      width: 100px !important;
      height: 100px !important;
    }
    .big {
      width: 320px !important;
    }
  }
}
@media screen and(max-width:850px) {
  .title {
    font-size: 25px !important;
  }
  .content {
    li {
      width: 80px !important;
      height: 80px !important;
    }
    .big {
      width: 260px !important;
    }
  }
}
@media screen and(max-width:680px) {
  .title {
    font-size: 20px !important;
  }
  .content {
    li {
      width: 60px !important;
      height: 60px !important;
    }
    .big {
      width: 200px !important;
    }
  }
}
@media screen and(max-width:538px) {
  .title {
    font-size: 18px !important;
  }
  .content {
    li {
      width: 40px !important;
      height: 40px !important;
    }
    .big {
      width: 140px !important;
    }
  }
}
.staffStyle {
  width: 100%;
  .main {
    width: 75%;
    margin: 50px auto;
    .title {
      color: #d20505;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    .content {
      margin: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        transform: scale(1);
        transition: 0.5s;
      }
      li:hover {
        z-index: 1;
        img:hover {
          transform: scale(1.3);
          transition: 0.5s;
        }
      }
      > ul {
        display: flex;
        li {
          text-align: center;
          margin: 5px 5px;
          width: 140px;
          height: 140px;
        }
        .big {
          width: 440px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .small {
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .mini {
          position: relative;
          > img {
            width: 70%;
            position: absolute;
          }
        }
      }
      > ul:first-child {
        .left {
          > img {
            right: 0;
            bottom: 0;
          }
        }
        .right {
          > img {
            left: 0;
            bottom: 0;
          }
        }
      }
      > ul:not(:first-child) {
        .left {
          > img {
            right: 0;
            top: 0;
          }
        }
        .right {
          > img {
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}
</style>
